import { baseUrl } from "./../configs/urlConfig";
import { httpGET } from "./Intaceptor";

export const getAllSupportDocuments = async () => {
  const url = baseUrl + "support-documents";
  return await httpGET(url);
};
export const findSupportDocuments = async (searchText) => {
  const url = baseUrl + "support-documents?_q="+searchText;
  return await httpGET(url);
};
export const getAllSupportDocumentsInFeatureId = async (feature_id) => {
  const url = baseUrl + "support-documents?Feature.id_eq="+feature_id;
  return await httpGET(url);
};
export const getAllFeatures = async () => {
  const url = baseUrl + "features";
  return await httpGET(url);
};
export const getAllModules = async () => {
  const url = baseUrl + "modules";
  return await httpGET(url);
};
export const getSubFeaturesInFeatureId = async (feature_id) => {
  const url = baseUrl + "sub-features?Feature.id_eq="+feature_id;
  return await httpGET(url);
};

// search
// const url = baseUrl + "support-documents?_q=document";