import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";



const DocSidebarContent = ({contentList=[],selectFeatureId=()=>{}}) => {
  return (
    <div className="accordion-style-two doc-sidebar-menu">
      <div className="faq-wrraper ">
        <Accordion preExpanded={[0]} allowZeroExpanded>
          {contentList.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={i}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h4 className="mb-0">
                    <button className="btn btn-link">{item.Name}</button>{" "}
                  </h4>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <ul className="sub-menu cursor-pointer">
                  {item.Features.map((val, i) => (
                    <li key={i} onClick={()=>{selectFeatureId(val.id,item.Slug,val.Slug)}}>
                      <a className={val.activeClass}>
                        {val.Name}
                      </a>
                    </li>
                  ))}
                </ul>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default DocSidebarContent;
