import React, { useState, useEffect } from "react";
import ScrollspyNav from "react-scrollspy-nav";
import FormEvent from "../../../components/form/FormDoc";
import HeaderThree from "../../../components/header/HeaderThree";
import DocSidebarContent from "../docs/DocSidebarContent";
import {
  getAllSupportDocuments,
  getAllModules,
  getSubFeaturesInFeatureId,
  getAllSupportDocumentsInFeatureId,
  findSupportDocuments,
} from "./../../../services/supportDocumentService";
import ReactMarkdown from "react-markdown";

import searchIcon from "../../../assets/images/icon/59.svg";
import docLoader from "../../../assets/images/loader/support_doc_loader.gif";
import { useHistory, useParams } from "react-router";
import queryString from 'query-string';

import BillaLogo from "../../../assets/images/icon/BillaCloud/BillableLogo.png";
import { useLocation } from 'react-router-dom';

const DocFullWidthBanner = () => {
  const [docList, setDocList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [subFeaturesList, setSubFeaturesList] = useState([]);
  const [click, setClick] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const handleClick = () => setClick(!click);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getSupportDocuments();
    getModules();
  }, []);

  const getSupportDocumentsInFeatureId = async (featureId) => {
    setLoader2(true);
    const response = await getAllSupportDocumentsInFeatureId(featureId);
    if (response.status === 200) {
      setDocList(response.data);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  const getSupportDocuments = async (featureId) => {
    setLoader(true);
    const response = await getAllSupportDocuments(featureId);
    if (response.status === 200) {
      setDocList(response.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getModules = async () => {
    setLoader(true);
    const response = await getAllModules();
    if (response.status === 200) {
      setFeaturesList(response.data);
      setLoader(false);
      getSubFeaturesList(response.data[0].Features[0].id);
    } else {
      setLoader(false);
    }
  };

  const getSelectFeatureId = (featureId, route1, route2) => {
    history.push("/support-docs/" + route1 + "/" + route2);
    getSupportDocumentsInFeatureId(featureId);
    getSubFeaturesList(featureId);
  };

  const searchSupportDocuments = async (searchText) => {
    setLoader2(true);
    const response = await findSupportDocuments(searchText);
    if (response.status === 200) {
      setDocList(response.data);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  const getSubFeaturesList = async (featureId) => {
    const response = await getSubFeaturesInFeatureId(featureId);
    // const response = await getSubFeaturesInFeatureId(2);
    if (response.status === 200) {
      setSubFeaturesList(response.data);
      console.log(response.data);
    }
  };

  return (
    <div>
      {loader ? (
        <div className="col-md-12 col-xl-12" style={{ height: "100vh" }}>
          <img
            src={docLoader}
            className="m-auto"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        </div>
      ) : (
        <div>
          <HeaderThree />
          {/* End Header */}

          {/* 	=============================================
				Fancy Hero Five
			==============================================  */}
          <div className="fancy-hero-five">
            <div className="bg-wrapper">
              <div className="container">
                <div className="text-center">
                  <h1 className="heading">Support Docs</h1>
                  <p className="sub-heading space-xs">
                    Get support on Billable platform, We care you always
                  </p>
                </div>
                <div className="search-filter-form mt-30">
                  <FormEvent
                    searchDoc={(searchText) =>
                      searchSupportDocuments(searchText)
                    }
                  />
                </div>
              </div>
            </div>
            {/* /.bg-wrapper */}
          </div>
          {/* /.fancy-hero-five */}

          {/* =============================================
            Documentation
        ==============================================  */}
          <div className="doc-container doc-banner full-width mt-70 sm-m0">
            <div className="clearfix">
              <div className="row flex-xl-nowrap no-gutters">
                {/* ****************************** DOC SIDEBAR ********************************* */}
                <div className="col-md-3 col-xl-2 doc-sidebar">
                  <div className="clearfix">
                    <div className="mob-header">
                      <button className="toggler-menu" onClick={handleClick}>
                        <div className={click ? "active" : ""}>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </button>
                    </div>
                    {/* End Header */}
                  </div>
                  <nav
                    className={
                      click
                        ? "doc-links collapse clearfix nav-fix  show"
                        : "doc-links collapse clearfix nav-fix"
                    }
                    id="doc-sidebar-nav"
                  >
                    <div className="search-form">
                      <form action="#">
                        <input type="text" placeholder="Search here.." />
                        <button>
                          <img src={searchIcon} alt="searchIcon" />
                        </button>
                      </form>
                    </div>
                    <DocSidebarContent
                      selectFeatureId={(featureId, a, s) =>
                        getSelectFeatureId(featureId, a, s)
                      }
                      contentList={featuresList}
                    />
                  </nav>
                  {/* /.doc-links */}
                </div>
                {/*  /.doc-sidebar */}

                {/* ****************************** DOC MAIN BODY ********************************* */}

                {loader2 ? (
                  <main className="col-md-9 col-xl-8">
                    <img src={docLoader} className="m-auto" />
                  </main>
                ) : (
                  <main className="col-md-9 col-xl-8 doc-main-body">
                    {docList.length > 0 ? (
                      docList.map((doc, index) => {
                        return (
                          <div
                            id={
                              doc.SubFeature !== null ? doc.SubFeature.Name : ""
                            }
                          >
                            <ReactMarkdown>{doc.ContentRich}</ReactMarkdown>
                            <hr />
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        <h2>No Support Doc</h2>
                      </div>
                    )}
                  </main>
                )}

                <nav className="d-none d-xl-block col-xl-2 doc-sideNav">
                  <div className="wrapper">
                    <nav className="main-side-nav">
                      <ScrollspyNav
                        scrollTargetIds={subFeaturesList.map(
                          (subFeature, index) => (
                            <p>{subFeature.Name}</p>
                          )
                        )}
                        activeNavClass="active"
                        offset={-90}
                        scrollDuration="300"
                      >
                        {subFeaturesList.map((subFeature, index) => (
                          <a href={"#" + subFeature.Name} className="nav-link">
                            {subFeature.Name}
                          </a>
                        ))}
                        {/* <a href="#installing" className="nav-link">
                      Installing Deski
                    </a>
                    <a href="#verifying" className="nav-link">
                      Verifying Installation
                    </a>
                    <a href="#manual-installation" className="nav-link">
                      Manual Installation
                    </a>
                    <a href="#running-example" className="nav-link">
                      Running example website
                    </a>
                    <a href="#launching-server" className="nav-link">
                      Launching the server
                    </a>
                    <a href="#loading-dialog" className="nav-link">
                      Loading dialog
                    </a> */}
                      </ScrollspyNav>
                    </nav>
                  </div>
                </nav>
                {/* End Right Sidebar Menu */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocFullWidthBanner;
