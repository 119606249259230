import React, { useState } from "react";
import searchIcon from './../../assets/images/icon/54.svg'

const FormEvent = ({ searchDoc = () => {} }) => {
  const [searchText, setSearchText] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <form onClick={handleSubmit}>
      <input
        type="text"
        placeholder="Search Somthing.."
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      {/* serarch field */}

      <button
        onClick={() => {
          searchDoc(searchText);
        }}
      >
        <img src={searchIcon} alt="icon" />
      </button>
      {/* Search button */}

      {/* <select className="form-control" id="exampleFormControlSelect1">
        <option>All</option>
        <option>Layout</option>
        <option>API</option>
        <option>Doc</option>
      </select> */}
      {/* End select menu */}
    </form>
  );
};

export default FormEvent;
